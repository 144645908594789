<template>
  <div v-if="obj" class="report-wrapper">
    <ul v-if="obj.data.length">
      <li v-for="(item, index) in obj.data" @click="onShowReportFn(item)" :key="index">
        <div class="top-box">
          <div class="left">
            <img :src="item.user_info.avatar" />
            <span>{{ item.user_info.nickname }}</span>
          </div>
          <div class="right">
            <span>查看报告</span>
            <img src="@/assets/image/more-icon.png" />
          </div>
        </div>
        <div class="bottom-box">
          <div class="left">
            <div class="title">{{ item.info.title }}</div>
            <div class="date">{{ item.create_time }}</div>
          </div>
          <div class="right">
            <img :src="item.info.cover" />
            <div class="right-fixed">
              <div v-if="item.is_counting === 1" class="num">评分中...</div>
              <div v-if="item.is_counting === 0" class="num">
                {{ item.total_score }}
              </div>
              <span v-if="item.is_counting === 0">分</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <!--报告弹窗-->
    <el-dialog
      :visible.sync="centerDialogVisible"
      :modal="false"
      append-to-body
      width="400px"
      top="5vh"
      center
    >
      <iframe :src="report_url" width="100%" height="750px" frameborder="no" />
    </el-dialog>
  </div>
</template>

<script>
import { platform, H5Url } from '@/api/config'

export default {
  props: {
    obj: Object
  },
  data() {
    return {
      H5Url,
      platform,
      report_url: false, // 报告url
      centerDialogVisible: false // 显示报告状态
    }
  },
  methods: {
    // 显示隐藏报告
    onShowReportFn(item) {
      if (this.platform === 'msyz') {
        this.report_url = `${this.H5Url}pages/aiInterviewData/aiInterviewReport/index?aiypid=${item.id}`
      } else {
        this.report_url = `${this.H5Url}pages/aiInterviewSystem/aiInterviewRecordList/reportDetails/index/?report_id=${item.id}`
      }
      this.centerDialogVisible = true
    }
  }
}
</script>

<style lang="stylus" scoped>
.report-wrapper {
  width: 100%;
  height: auto;
  padding: 24px 0;

  ul {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 100%;
      height: auto;
      margin-bottom: 24px;
      border-radius: 13px;
      transition: all 0.2s;
      cursor: pointer;
      box-shadow: 0 6px 10px 0 rgba(95, 101, 105, 0.15);
      background: #fff;

      .top-box {
        width: 100%;
        height: auto;
        padding: 13px 17px 12px 20px;
        border-bottom: 1px solid #ECEAE6;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;

        .left {
          display: flex;
          align-items: center;

          img {
            display: block;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            overflow: hidden;
          }

          span {
            padding-left: 8px;
            font-size: 14px;
            color: #34271D;
          }
        }

        .right {
          display: flex;
          align-items: center;

          span {
            padding-right: 5px;
            font-size: 14px;
            color: #34271D;
          }

          img {
            display: block;
            width: 14px;
            height: 14px;
          }
        }
      }

      .bottom-box {
        width: 100%;
        height: auto;
        padding: 20px;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        .left {
          flex: 1;
          height: auto;

          .title {
            font-size: 18px;
            line-height: 18px;
            font-weight: 500;
            color: #34271D;
          }

          .date {
            padding-top: 18px;
            font-size: 14px;
            line-height: 14px;
            color: #7B756F;
          }
        }

        .right {
          position: relative;
          width: 166px;
          height: 100px;
          border-radius: 8px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .right-fixed {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            border-radius: 8px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.7);

            .num {
              font-size: 24px;
              line-height: 24px;
              font-weight: bold;
              color: #FFDD38;
            }

            span {
              padding-right: 2px;
              font-size: 14px;
              color: #FFDD38;
            }
          }
        }
      }
    }

    li:hover {
      transform: translateY(-2px);
      box-shadow: 0 6px 10px 0 rgba(95, 101, 105, 0.15);
    }

    li:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
